<template>
  <div>
    <!-- <img src="/img/demo/brand.jpg" class="demo" /> -->
    <div class="p1 relative">
      <img id="bp1" class="block" src="/img/brand/1.jpg" />
      <div class="absolute">
        <img data-aos="fade-up" src="/img/brand/1-1.png" />
      </div>
    </div>

    <div id="bp2" class="overflow-hidden">
      <img class="float-right block p2" src="/img/brand/2.jpg" />
    </div>
    <img class="block p3" src="/img/brand/3.jpg" />

    <img class="block p4" src="/img/brand/4.png" />
    <div class="p5">
      <img ref="img" @load="onLoad" class="p5-img" src="/img/brand/5.png" />
      <a
        class="ctrl left"
        href="javascript:"
        @mouseenter="startTranslate('right')"
        @click="startTranslate('right')"
      ></a>
      <a
        @mouseenter="startTranslate('left')"
        @click="startTranslate('left')"
        class="ctrl right"
        href="javascript:"
      ></a>
    </div>
    <div class="overflow-hidden p6">
      <img class="float-right block" src="/img/brand/6.png" />
    </div>
    <div id="bp7" class="p7">
      <img src="/img/brand/7.png" />
    </div>
    <img id="bp8" class="p8" src="/img/brand/8.jpg" />
  </div>
</template>
<script>
  import { getPageOffsetY, getScrollTop } from '@/utils/dom'
  import aos from '@/mixins/aos'
  export default {
    mixins: [aos],
    data() {
      return {
        translate: {
          init: false,
          start: 0,
          end: 0,
          current: 0,
          speed: 60,
          direction: null,
        },
      }
    },

    methods: {
      onLoad() {
        const y = getPageOffsetY(this.$refs.img)
        this.translate.start = this.getTranslateX(this.$refs.img)
        this.translate.end = -(
          this.$refs.img.width -
          document.documentElement.clientWidth * 0.8
        )

        document.addEventListener('scroll', () => {
          if (this.translate.direction == 'right') {
            return
          }
          let scrollTop = getScrollTop()
          if (scrollTop + document.documentElement.clientHeight > y) {
            this.startTranslate('left')
          }
        })
      },
      startTranslate(direction) {
        if (direction == this.translate.direction) {
          return
        }
        this.stopTranslate()
        setTimeout(() => {
          this.translate.direction = direction || this.translate.direction
          const target =
            direction == 'left' ? this.translate.end : this.translate.start

          const duration =
            Math.abs(target - this.translate.current) / this.translate.speed

          const transform = `translateX(${target}px)`
          const transition = `all ${duration}s linear`
          this.$refs.img.style.transition = transition
          this.$refs.img.style.transform = transform
        }, 100)
      },
      stopTranslate() {
        const img = this.$refs.img
        this.translate.current = this.getTranslateX(img)
        const transformStyle = `translateX(${this.translate.current}px)`

        this.$refs.img.style.transition = ''
        this.$refs.img.style.transform = transformStyle
      },

      getTranslateX(el) {
        let { transform } = getComputedStyle(el)
        return this.parseMatrix(transform)[4]
      },
      parseMatrix(matrix) {
        const mat = matrix.match(/^matrix\((.+)\)$/)
        return mat ? mat[1].split(', ') : 0
      },
    },
  }
</script>
<style lang="scss" scoped>
  .p1 {
    .absolute {
      right: 1.6%;
      bottom: -0.2%;
      width: 80.6%;
      > img {
        width: 80%;
      }
    }
  }
  .p2 {
    width: 88%;
    margin-top: 60px;
  }
  .p3 {
    width: 82%;
    margin-top: 78px;
  }
  .p4 {
    width: 82%;
    margin-top: 260px;
  }
  .p5 {
    position: relative;
    margin-top: 100px;
    overflow-x: hidden;
    .ctrl {
      position: absolute;
      top: 0;
      width: 30%;
      height: 100%;
      cursor: default;
    }
    .left {
      left: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 50%
      );
    }
    .right {
      right: 0;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 50%
      );
    }
  }
  .p5-img {
    width: auto;
    max-width: none;
    height: 650px;
    transform: translateX(350px);
  }
  .p6 {
    margin-top: 145px;
    overflow-x: hidden;

    img {
      transform: translateX(90px);
    }
  }
  .p7 {
    padding: 135px 344px 125px 385px;
    background-color: #eee;
  }
  .p8 {
    margin-top: 160px;
    margin-bottom: 140px;
  }
</style>
