export function getScrollTop() {
  return (
    document.documentElement.scrollTop ||
    window.pageYOffset ||
    document.body.scrollTop
  )
}
export function getRect(el) {
  const rect = el.getBoundingClientRect()
  return rect
}

export function getPageOffsetY(el) {
  const rect = el.getBoundingClientRect()
  return rect.top + getScrollTop()
}
