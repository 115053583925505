import AOS from 'aos'
export default {
  data() {
    return {
      aosData: {
        si: null,
        count: 0,
      },
    }
  },
  mounted() {
    AOS.init({
      mirror: true,
      duration: 1500,
      anchorPlacement: 'center-center',
      offset: 0,
    })

    this.aosData.si = setInterval(() => {
      if (this.aosData.count >= 10) {
        clearInterval(this.aosData.si)
      }
      AOS.refresh()
      this.aosData.count++
    }, 1000)
  },
}
